/*
 * Resideo/LifeWhere
 * Copyright (C) 2018-2023 Resideo/LifeWhere
 * mailto:nathan.williams@resideo.com
 */

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router';
import IdleTimer from 'react-idle-timer';
import IdleModal from './components/IdleModal';
import Sidebar from './components/Sidebar/Sidebar';
import { Landing } from './components/Landing';
import Dashboard from './components/Dashboard/Dashboard';
import Account from './components/Account/Account';
import Home from './components/Home/Home';
import Assets from './components/Assets/Assets';
import Devices from './components/Devices/Devices';
import InstallCheck from './components/Devices/InstallationCheck';
import Provisioning from './components/Provisioning/Provisioning';
import RegisterUser from './components/RegisterUser/RegisterUser';
import AccountManagement from './components/AccountManagement/Account/AccountManagement';
import Users from './components/AccountManagement/Users/Users';
import InviteHomeowner from './components/InviteHomeowner/InviteHomeowner';
import InternalTriageAlerts from './components/InternalTriageAlerts/InternalTriageAlerts';
import AlertTriageDetails from './components/InternalTriageAlerts/AlertTriageDetails';
import AlertTriageDashboard from './components/InternalTriageAlerts/AlertTriageDashboard';
import TriageAlerts from './components/TriageAlerts/TriageAlerts';
import AlertHistory from './components/AlertHistory/AlertHistory';
import AlertQuestions from './components/AlertQuestions/AlertQuestions';
import CustomerAlerts from './components/CustomerAlerts/CustomerAlerts';
import CurrentJob from './components/CurrentJob/CurrentJob';
import AddPartner from './components/AddPartner/AddPartner';
import BillingInfo from './components/BillingInfo/BillingInfo';
import Subscriptions from './components/Subscriptions/Subscriptions';

import IotTest from './components/Tools/IotTest';
import AccessKeyTool from './components/Tools/AccessKeyTool';
import ManageAssets from './components/Tools/ManageAssets';
import EmailStatus from './components/Tools/EmailStatus';
import OTALogs from './components/Tools/OTALogs';

import ACOnboarding from './components/Operations/ACOnboarding';
import ACAssetTrainingReview from './components/Operations/ACAssetTrainingReview';
import ACAlertTrainingReview from './components/Operations/ACAlertTrainingReview';
import FurnaceOnboarding from './components/Operations/FurnaceOnboarding';
import FurnaceAssetTrainingReview from './components/Operations/FurnaceAssetTrainingReview';
import FurnaceAlertTrainingReview from './components/Operations/FurnaceAlertTrainingReview';

import registerServiceWorker from './registerServiceWorker';
import MainAppBar from './components/AppBar/AppBar';
import ManageFavorites from './components/AppBar/ManageFavorites';
import UserAccount from './components/AccountManagement/Account/UserAccount';
import Map from './components/Map/Map';

import AccountService from "./services/account.service";
import CustomerService from "./services/customer.service";
import PartnerService from "./services/partner.service";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './custom.css';

import Login from "./containers/Login";
import ScheduleRepairs from './components/ScheduleRepairs/ScheduleRepairs';
import { Spinner } from 'react-bootstrap';
import { jsAsset, jsHouse, jsCurrentAccount } from "./componentObjects";
import { withStyles } from '@material-ui/core/styles';

import ReactGA from 'react-ga';
//import { createBrowserHistory } from 'history';
import { initGA, ModalView, Event, EventNonInt } from './components/GoogleAnalytics';

import ChangePassword from './components/ChangePassword/ChangePassword';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import UserManagement from "./components/AccountManagement/Users/UserManagement";
import RegisterHomeowner from './components/RegisterUser/RegisterHomeowner';
import ConfirmationEmail from './components/Devices/ConfirmationEmail';
import Eula from './components/Eula/Eula';
import PartnerEnergyMetrics from './components/EnergyMetrics/PartnerEnergyMetrics';
import DeletedAccounts from './components/DeletedAccounts/DeletedAccounts';
import InviteAdmins from './components/Tools/InviteAdmins';
import loginService from "./services/login.service";
import userfavoriteService from "./services/userfavorite.service";

//import { IotTest } from './components/Tools/IotTest';

//const history = createBrowserHistory();

/* Initialize google analytics page view tracking
const trackingId = "G-0PGQM1LVZS";
ReactGA.initialize(trackingId, { debug: true });
ReactGA.pageview(window.location.pathname);*/

/*history.listen(location => {
    var location = location.location;
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});*/

//ReactGA.set({
//    userName: localStorage.getItem('username') || null,
//    isPartnerAdmin: localStorage.getItem('isPartnerAdmin') || false,
//    /* any data that is relevant to the user session
//    that you would like to track with google analytics*/
//    });

const styles = theme => ({
    root: {
        marginLeft: '225px',
        paddingRight: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    },
    drawer: {
        display: 'block',
        [theme.breakpoints.down('md')]: {
            width: '0px !important',
            display: 'none'
        },
    },
    burgerMenu: {
        zIndex: '9',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            width: '225px !important'
        },
    },
});

const ProtectedRoute = ({ render: Comp, loggedIn, path, ...rest }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={props => {
                return loggedIn ? (
                    <Comp {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    prevLocation: path,
                                    error: "You need to login first!",
                                },
                            }}
                        />
                    );
            }}
        />
    );
};

class App extends Component {
    static displayName = App.name;
    static baseUrl;
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.state = {
            currentPartnerId: localStorage.getItem('currentPartnerId') || "-1", 
            currentCustomerId: localStorage.getItem('currentCustomerId') || "-1",
            currentAccount: {
                firstName: localStorage.getItem('firstName') || null,
                lastName: localStorage.getItem('lastName') || null,
                email: localStorage.getItem('email') || null,
                picture: localStorage.getItem('picture') || null 
            },
            serviceTier: localStorage.getItem('serviceTier') || "-1",
            hasServiceTier: false,
            hasServiceTitan: false,
            hasBilling: false,
            isActive: false,
            loggedInCustomerOrganization: localStorage.getItem('loggedInCustomerOrganization') || "",
            isPartnerAdmin: localStorage.getItem('isPartnerAdmin') === 'true' || false,
            isPortalAdmin: localStorage.getItem('isPortalAdmin') === 'true' || false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            userName: localStorage.getItem('username') || null,
            userId: localStorage.getItem('userId') || null, 
            roles: localStorage.getItem('roles') || [],
            loading: true,
            newLogin: false,
            loggedIn: JSON.parse(localStorage.getItem('isLoggedIn')) || false,
            timeout: 1000 * 15 * 60,
            showIdleModal: false,
            isTimedOut: false,
            registerUserId: -1,
            registerOrgId: -1,
            registerParentOrgId: -1,
            regUserLatitude: "",
            regUserLongitude: "",
            changePasswordUri: null,
            changePasswordIsNewUser: false,
            logo: localStorage.getItem('logo') || null,
            burgerClicked: false,
            reloadSidebar: true,
            partnerListChanged: false,
            emailLoaded: false,
            addressLoaded: false,
            forgotPassword: false,
            passwordEmailSent: false,
            isMobile: false,
            reparentStatus: "Loading Customer Account",

            showAllTraining: true,

            isLoadingComplete: false,
            activePath: localStorage.getItem('activePath') || "/",
            partnerList: [],
            customerList: [],
            favoritesList: localStorage.getItem('favorites') || [],
            favoriteCustomers: JSON.parse(localStorage.getItem('favoriteCustomers') || "[]"),
            favoriteAssets: JSON.parse(localStorage.getItem('favoriteAssets') || "[]"),
            faveAssetPath: localStorage.getItem('faveAssetPath') || [],
            rentalAgreementCustomerValue : -1,
            attributeList: [],
            lastPartnerObj: {}
        };

        //for idle logout 
        this.idleTimer = null;
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.handleBurgerClick = this.handleBurgerClick.bind(this);
        this.onShowIdleModal = this.onShowIdleModal.bind(this);
        this.onLoggedInUserChange = this.onLoggedInUserChange.bind(this);
        this.onLogOff = this.onLogOff.bind(this);
        this.setAccountInfo = this.setAccountInfo.bind(this);

        this.onForgotPassword = this.onForgotPassword.bind(this);
        this.passwordEmailSent = this.passwordEmailSent.bind(this);
        this.endForgotPassword = this.endForgotPassword.bind(this);

        this.switchToNewPartner = this.switchToNewPartner.bind(this);
        this.setCurrentPartnerId = this.setCurrentPartnerId.bind(this);
        this.setCurrentCustomerId = this.setCurrentCustomerId.bind(this);
        this.setCurrentPartnerAndCustomer = this.setCurrentPartnerAndCustomer.bind(this);
        this.setAssetFromFavorites = this.setAssetFromFavorites.bind(this);
        this.setLoadingFromChild = this.setLoadingFromChild.bind(this);
        this.setActivePath = this.setActivePath.bind(this);
        this.setServiceTier = this.setServiceTier.bind(this);
        this.setServiceTitan = this.setServiceTitan.bind(this);
        this.setBilling = this.setBilling.bind(this);
        this.setIsActive = this.setIsActive.bind(this);
        this.getCustomerList = this.getCustomerList.bind(this);
        this.setFavoritesList = this.setFavoritesList.bind(this);
        this.onSaveFaves = this.onSaveFaves.bind(this);
        this.setReparent = this.setReparent.bind(this);
        this.setShowAllTraining = this.setShowAllTraining.bind(this);
        this.AgreementCustomerValue = this.AgreementCustomerValue.bind(this);
        this.setAttributeList = this.setAttributeList.bind(this);
    }    

    componentWillMount() {
        initGA();

        this.baseUrl = window.location.href;
        
        if (this.state.loggedIn === true && (this.state.currentAccount.email === "" || this.state.currentAccount.email === null || this.state.currentAccount.email === "null"))
            this.setState({ loggedIn: false });

        if (this.baseUrl.toLowerCase().indexOf("registration") >= 0) {
            let urlParams = new URLSearchParams(this.baseUrl.substring(this.baseUrl.indexOf('?')));
            let myParam = urlParams.get('email');

            if (myParam != null) {
                this.setState({ registerUserId: myParam });
            }

            if (this.baseUrl.toLowerCase().indexOf("homeownerregistration") >= 0) {
                let myParam2 = urlParams.get('orgid');
                if (myParam2 != null) {
                    this.setState({ registerOrgId: myParam2 });
                }

                let parentParam = urlParams.get('parentOrgId');
                if (parentParam != null) {
                    this.setState({ registerParentOrgId: parentParam });
                }

                let latParam = urlParams.get('latitude');
                if (latParam != null) {
                    this.setState({ regUserLatitude: latParam });
                }

                let longParam = urlParams.get('longitude');
                if (longParam != null) {
                    this.setState({ regUserLongitude: longParam });
                }
            }
        }
        else if (this.baseUrl.toLowerCase().indexOf("changepassword") >= 0) {
            let urlParams = new URLSearchParams(this.baseUrl.substring(this.baseUrl.indexOf('?')));
            let myParam = urlParams.get('email');

            if (myParam != null) {
                this.setState({ registerUserId: myParam });
            }      

            let myUriParam = urlParams.get('uri');
            if (myUriParam != null) {
                this.setState({ changePasswordUri: myUriParam });
            }

            let myMobileParam = urlParams.get('ismobile');
            if (myMobileParam != null) {
                this.setState({ isMobile: myMobileParam });
            }

            let myNewUserParam = urlParams.get('isNewUser');
            if (myNewUserParam != null) {
                this.setState({ changePasswordIsNewUser: myNewUserParam });
            }
        }
        else if (this.baseUrl.toLowerCase().indexOf("forgotpassword") >= 0) {
            this.setState({ forgotPassword: true });

            let urlParams = new URLSearchParams(this.baseUrl.substring(this.baseUrl.indexOf('?')));
            let myMobileParam = urlParams.get('ismobile');
            if (myMobileParam != null) {
                this.setState({ isMobile: myMobileParam, forgotPassword: true });
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const config = {
            onUpdate(registration) {
                if (registration && registration.waiting) {
                        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                        window.location.reload(true);
                }
            }
        };

        if ((this.state.currentAccount.email === "" || this.state.currentAccount.email === null || this.state.currentAccount.email === "null"))
            this.setState({ loggedIn: false });
        registerServiceWorker(config);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setLoadingFromChild(value) {
        this.setState({ loading: value });
    }

    setReparent(statusMessage) {
        this.setState({
            reparentStatus: statusMessage
        });
    }

    AgreementCustomerValue(Value) {
       
        this.setState({
            rentalAgreementCustomerValue: parseInt(Value)
        });
    }
    setAttributeList(attrlist) {
        this.setState({ attributeList: attrlist });
     }

    setAttributeList(attrlist) {
        this.setState({ attributeList: attrlist });
    }

    switchToNewPartner(partnerList, partner) {
        if (partnerList !== undefined && partner !== undefined) {
            this.setState({ partnerList: partnerList }, () => {
                this.setState({
                    currentPartnerId: partner !== undefined ? partner.organizationId : "-1",
                    currentCustomerId: "-1"
                },
                    () => {
                        this.setState({ activePath: "/accountManagement", reloadSidebar: true }, () => {
                            localStorage.setItem('activePath', "/accountManagement");
                            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
                            this.props.history.push("/accountManagement");
                        });
                    });
            });
        }
        
    }

    onLoggedInUserChange(e) {
        this.setState({ roles: e.roles, userName: e.userName, userId: e.userId, logo: "data:image/png;base64," + e.logo }, () => {

            this.setState({ loggedIn: true, loggedInCustomerOrganization: e.organization, isPartnerAdmin: this.state.roles.includes("PARTNER ADMIN"), isPortalAdmin: this.state.roles.includes("PORTAL ADMIN"), isCustomerSupport: this.state.roles.includes("CUSTOMER SUPPORT"), currentPartnerId: "-1", currentCustomerId: "-1", showAllTraining: false }, () => {
                localStorage.setItem('isLoggedIn', true);
                localStorage.setItem('roles', JSON.stringify(this.state.roles));
                localStorage.setItem('username', this.state.userName);
                localStorage.setItem('userId', this.state.userId);
                localStorage.setItem('loggedInCustomerOrganization', this.state.loggedInCustomerOrganization);
                localStorage.setItem('logo', this.state.logo);
                localStorage.setItem('isPartnerAdmin', this.state.isPartnerAdmin);
                localStorage.setItem('isPortalAdmin', this.state.isPortalAdmin);
                localStorage.setItem('isCustomerSupport', this.state.isCustomerSupport);
                localStorage.setItem('currentPartnerId', "-1");
                localStorage.setItem('currentCustomerId', "-1");
                localStorage.setItem('showAllTraining', false);
                localStorage.setItem('activePath', "/");
            });            
        });
    }

    setAccountInfo = (user) => {
        let newName = user.firstName !== null && user.firstName !== "" ? user.firstName : user.email;
        this.setState({ currentAccount: user, userName: newName, logo: "data:image/png;base64," + user.picture }, async () => {
            localStorage.setItem('username', this.state.userName);
            localStorage.setItem('logo', this.state.logo);
            localStorage.setItem('firstName', this.state.currentAccount.firstName); 
            localStorage.setItem('lastName', this.state.currentAccount.lastName);
            localStorage.setItem('email', this.state.currentAccount.email);
            localStorage.setItem('picture', this.state.currentAccount.picture);

            if (this.state.currentAccount.email === null || this.state.currentAccount.email === undefined || this.state.currentAccount.email === "null") {
                this.onLogOff();
            }

            /*await AccountService.getuserfavorites(this.state.currentAccount.email)
                .then(response => {
                    this.setState({ favoritesList: response.data }, () => {
                        this.sortFavorites();
                    });
                })
                .catch(e => {
                    console.log(e);
                });*/
        });
    }

    setCurrentPartnerId(id, setPartnerCallback, reloadList = false) {
        // This should only be for when the Partners selection in the SideNav is changed
        // If it's just a page refresh, this method gets called but we want to maintain the Customer selected.
        if (this.state.currentPartnerId !== id) {
            this.setState({ currentCustomerId: "-1" });
        }

        if (this.state.partnerList.findIndex(p => p.organizationId === id) < 0) {
            let partnerObj = {
                organization: this.state.loggedInCustomerOrganization,
                isPartnerAdmin: this.state.isPartnerAdmin,
                isActive: true
            };
            if (reloadList) {
                this.setState({ loading: true, lastPartnerObj: partnerObj }, async () => {
                    await PartnerService.get(partnerObj) //this.props.loggedInCustomerOrganization, this.props.isPartnerAdmin, true) 
                        .then(response2 => {
                            let newPartnerList = response2.table;
                            this.setState({ partnerList: newPartnerList });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                });
            }
        }

        this.setState({ currentPartnerId: id },
	         () => {
            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId); //todo check 
            setPartnerCallback();
        });                
    }

    setCurrentCustomerId(id, setCustomerCallback) {
        this.setState({ currentCustomerId: id }, () => {
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId);
            setCustomerCallback();
        });
    }

    setCurrentPartnerAndCustomer(id, customerId, setPartnerCallback) {
        /* In case we want the list cleared so no other customer name pops up briefly
        if (id !== this.state.currentPartnerId) {
            console.log("ID, CURRENTPARTNERID ", id, this.state.currentPartnerId);
            this.setState({customerList:[]})
        }
        */

        this.getCustomerList(id);

        this.setState({
            currentPartnerId: id, currentCustomerId: customerId
        }, async () => {
            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId);
            setPartnerCallback();
        });
    }

    setAssetFromFavorites(id, customerId, asset, setAssetCallback) {
        let assetPath = [asset.houseId, asset.hvacId, asset.assetId];
        this.setState({ currentPartnerId: id, currentCustomerId: customerId, faveAssetPath: assetPath }, async () => {
            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId);
            localStorage.setItem('faveAssetPath', JSON.stringify(this.state.faveAssetPath));
            
            await this.getCustomerList(this.state.currentPartnerId);
            setAssetCallback();
        });
    }

    setFavoritesList(list) {
        this.setState({ favoriteCustomers: list });
    }

    setAssetFavorites(list) {

    }

    setShowAllTraining(showAll) {
        this.setState({ showAllTraining: showAll }, () => {
            localStorage.setItem('showAllTraining', this.state.showAllTraining);
        });
    }

    /*sortFavorites() {
        let favorites = this.state.favoritesList;
        if (favorites.length) {
            let customers = favorites.filter(f => f.favoriteTypeId === 1);
            let assets = favorites.filter(f => f.favoriteTypeId === 2);
            if (this._isMounted)
                this.setState({
                    favoriteCustomers: customers,
                    favoriteAssets: assets
                });
        }
    }*/

    async getCustomerList(partnerId) {
        await CustomerService.get(partnerId > 0 ? partnerId : this.state.partnerList[0].organizationId).then(customers => {
            this.setState({ customerList: customers, emailLoaded: false, addressLoaded: false, partnerListChanged: true })


            if (customers.length && this.state.currentCustomerId <= 0) {
                this.setState({ currentCustomerId: this.state.customerList[0].organizationId });
            }
            this.setLoadingFromChild(false);
        }).catch(e => {
            console.log(e)
        });
    }

    setActivePath(newPath) {
        this.setState({ activePath: newPath }, () => {
            localStorage.setItem('activePath', this.state.activePath);
        });
    }

    setServiceTier(tier) {
        let hasServiceTier = false;
        let serviceTier = parseInt(tier, 10);
        if (serviceTier > 0) 
            hasServiceTier = true;

        this.setState({ serviceTier: serviceTier, hasServiceTier: hasServiceTier });
    }

    setServiceTitan(hasST) {
        this.setState({ hasServiceTitan: hasST });
    }

    setBilling(hasBilling) {
        this.setState({ hasBilling: hasBilling });
    }

    setIsActive(isActive) {
        this.setState({ isActive: isActive });
    }

    onSaveFaves(newList) {
        this.setState({ favoritesList: newList, loading: true }, async () => {
            //this.sortFavorites();
            await userfavoriteService.updateuserfavorites(this.state.userName, newList)
                .then(response => {
                    if (response.data === true) {
                        this.setState({ loading: false });
                    }
                })
                .catch(e => {
                    console.log(e);
                })
        })
    }

    onForgotPassword() {
        this.setState({ activePath: "/forgotPassword", forgotPassword: true }, () => {
            localStorage.setItem('activePath', this.state.activePath);
        });
    }

    passwordEmailSent(sent) {
        this.setState({ passwordEmailSent: sent });
    }

    endForgotPassword() {
        this.setState({ activePath: "/", forgotPassword: false, passwordEmailSent: false, loggedIn: false }, () => {
            localStorage.setItem('activePath', "/");
            localStorage.setItem('isLoggedIn', false);
            this.props.history.push("/");
            if (this.baseUrl.toLowerCase().indexOf("registration"))
                window.location.reload(true);
        });
    }

    //for idle logout
    handleOnAction() { 
        console.log('user did something');
        this.setState({ isTimedOut: false });
    }

    handleOnActive() {
        console.log('user is active');
        console.log('time remaining', this.idleTimer.getRemainingTime());
        this.setState({ isTimedOut: false });
    }

    handleOnIdle() {
        console.log('user is idle');
        console.log('last active', this.idleTimer.getLastActiveTime());
        const isTimedOut = this.state.isTimedOut;
        if (isTimedOut) {
            EventNonInt("Idle Logout", "Idle timer forced user logout", "Idle Logout", true);
            this.onLogOff();
        }
        else {
            this.setState({ showIdleModal: true });
            ModalView("idle");
            this.idleTimer.reset();
            this.setState({ isTimedOut: true });
        }

    }

    onShowIdleModal = e => {
        this.setState({
            showIdleModal: !this.state.showIdleModal,
            isTimedOut: false 
        });
        window.scrollTo(0, 0);
    }

    handleBurgerClick() {
        this.setState({
            burgerClicked: !this.state.burgerClicked,
            reloadSidebar: false,
            loading: false
        });
    }

    async onLogOff() {
        if (!this.state.isTimedOut)
            Event("User Logout", "User clicked logout", "User Logout Option Clicked");
        await loginService.logout().then(response => {
            this.setState({ loggedIn: false, roles: [], userName: null, userId: null, homeList: [], hvacList: [], assetList: [], currentAccount: jsCurrentAccount, currentHVACSystem: jsHouse, currentHome: jsHouse, currentAsset: jsAsset, activePath: "/", favoriteCustomers: [] },
                () => {
                    localStorage.setItem('isLoggedIn', false);
                    this.props.history.push("/");
                    this.setState({ showIdleModal: false });
                    localStorage.setItem('token', null);
                    localStorage.setItem('currentPartnerId', "-1");
                    localStorage.setItem('currentCustomerId', "-1");
                    localStorage.setItem('username', null);
                    localStorage.setItem('userId', null);
                    localStorage.setItem('roles', JSON.stringify([]));
                    localStorage.setItem('loggedInCustomerOrganization', "");
                    localStorage.setItem('isPartnerAdmin', false);
                    localStorage.setItem('isPortalAdmin', false);
                    localStorage.setItem('isCustomerSupport', false);
                    localStorage.setItem('logo', null);
                    localStorage.setItem('firstName', null);
                    localStorage.setItem('lastName', null);
                    localStorage.setItem('email', null);
                    localStorage.setItem('picture', null);
                    localStorage.setItem('faveAssetPath', JSON.stringify([]));
                    localStorage.setItem('activePath', "/");
                    localStorage.setItem('favoriteCustomers', JSON.stringify([]));
                    localStorage.setItem('favoriteAssets', JSON.stringify([]));
                })
        });        
    }        

    render() {
        const { classes } = this.props;

        let contents = this.state.loading ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : <div>{null}</div>

        return this.baseUrl.toLowerCase().indexOf("eula") >= 0 ?
            <Eula />
            : this.baseUrl.toLowerCase().indexOf("registration") >= 0 ?
                (this.baseUrl.toLowerCase().indexOf("homeownerregistration") >= 0 ?
                    <RegisterHomeowner registerUserId={this.state.registerUserId} registerOrgId={this.state.registerOrgId} registerParentOrgId={this.state.registerParentOrgId} latitude={this.state.regUserLatitude} longitude={this.state.regUserLongitude} />
                    : <RegisterUser registeruserId={this.state.registerUserId} />)
                : this.baseUrl.toLowerCase().indexOf("changepassword") >= 0
                    ? <ChangePassword registerUserId={this.state.registerUserId} changePasswordUri={this.state.changePasswordUri} currentAccount={this.state.currentAccount} endForgotPassword={this.endForgotPassword} isMobile={this.state.isMobile} isNewUser={this.state.changePasswordIsNewUser}/>
                    : this.state.forgotPassword ?
                        (<ForgotPassword passwordEmailSent={this.passwordEmailSent} endForgotPassword={this.endForgotPassword} isMobile={this.state.isMobile} />)
                        : this.state.loggedIn ? (
                            <div>

                                {contents}

                                <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    timeout={this.state.timeout}
                                    onActive={this.handleOnActive}
                                    onIdle={this.handleOnIdle}
                                    debounce={500}
                                />
                                <React.Fragment>
                                    <Router>

                                        <MainAppBar userName={this.state.userName} logo={this.state.logo} onLogOff={this.onLogOff} currentAccount={this.state.currentAccount} currentCustomerId={this.state.currentCustomerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} isPartnerAdmin={this.state.isPartnerAdmin} serviceTier={this.state.serviceTier} handleBurgerClick={this.handleBurgerClick} favorites={this.state.favoriteCustomers} favoriteCustomers={this.state.favoriteCustomers} favoriteAssets={this.state.favoriteAssets} customers={this.state.customerList} partners={this.state.partnerList} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} setCurrentUser={this.setAccountInfo} selectedPartner={this.state.currentPartnerId} setFromFavorites={this.setCurrentPartnerAndCustomer} setAssetFromFavorites={this.setAssetFromFavorites} setFavoritesList={this.setFavoritesList} />
                                        {
                                            <div>

                                                <div className={this.state.burgerClicked ? classes.burgerMenu : classes.drawer}>
                                                    <Sidebar setLoadingFromChild={this.setLoadingFromChild} onLogOff={this.onLogOff} userName={this.state.currentAccount.email} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} roles={this.state.roles}
                                                        loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} isPartnerAdmin={this.state.isPartnerAdmin} reloadSidebar={this.state.reloadSidebar} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId}
                                                        setCurrentCustomerId={this.setCurrentCustomerId} setPartnerList={list => { this.setState({ partnerList: list }); }} setPartnerObj={obj => { this.setState({ lastPartnerObj: obj }); }} lastPartnerObj={this.state.lastPartnerObj} setServiceTier={this.setServiceTier} setServiceTitan={this.setServiceTitan} setBilling={this.setBilling} setIsActive={this.setIsActive}
                                                        customerList={this.state.customerList} setCustomerList={list => { this.setState({ customerList: list }); }} getCustomerList={this.getCustomerList} activePath={this.state.activePath} setActivePath={this.setActivePath} detailedCustomerList={this.state.detailedCustomerList} emailLoaded={this.state.emailLoaded} addressLoaded={this.state.addressLoaded} hasServiceTier={this.state.hasServiceTier} hasBilling={this.state.hasBilling} isActive={this.state.isActive} setCurrentUser={this.setAccountInfo} AgreementCustomerValue={this.AgreementCustomerValue} setAttributeList={this.setAttributeList} /></div>

                                                <IdleModal onClick={this.onShowIdleModal} show={this.state.showIdleModal} />
                                            </div>
                                        }

                                        <Switch>

                                            <ProtectedRoute exact path="/" loggedIn={this.state.loggedIn} render={(props) => <Landing isPortalAdmin={this.state.isPortalAdmin} roles={this.state.roles} />} />
                                            <ProtectedRoute exact path="/dashboard" loggedIn={this.state.loggedIn} render={(props) => <Dashboard {...props} currentAccount={this.state.currentAccount} currentPartnerId={this.state.currentPartnerId} setActivePath={this.setActivePath} setServiceTier={this.setServiceTier} isLoading={this.state.loading} serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} hasBilling={this.state.hasBilling} />} />
                                            <ProtectedRoute exact path="/lwTriageAlerts" loggedIn={this.state.loggedIn} render={(props) =>
                                                <InternalTriageAlerts {...props} partners={this.state.partnerList} isLoading={this.state.loading} userId={this.state.userId} currentUser={this.state.currentAccount} />}
                                            />
                                            <ProtectedRoute exact path="/alertTriageDetails" loggedIn={this.state.loggedIn} render={(props) => <AlertTriageDetails {...props} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/alertTriageDashboard" loggedIn={this.state.loggedIn} render={(props) => <AlertTriageDashboard {...props} isLoading={this.state.loading} setActivePath={this.setActivePath} _isMounted={this._isMounted} />} />
                                            <ProtectedRoute path="/triageAlerts" loggedIn={this.state.loggedIn} render={(props) => <TriageAlerts {...props} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} partners={this.state.partnerList} setActivePath={this.setActivePath} isLoading={this.state.loading} userOrg={this.state.loggedInCustomerOrganization} userName={this.state.currentAccount.email} hasServiceTitan={this.state.hasServiceTitan} serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} setServiceTier={this.setServiceTier/*tier => { this.setState({ serviceTier: tier }); }*/} />} />
                                            <ProtectedRoute exact path="/alertHistory" loggedIn={this.state.loggedIn} render={(props) => <AlertHistory {...props} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} setActivePath={this.setActivePath} isLoading={this.state.loading} userOrg={this.state.loggedInCustomerOrganization} />} />
                                            <ProtectedRoute exact path="/alertQuestions" loggedIn={this.state.loggedIn} render={(props) => <AlertQuestions {...props} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} setActivePath={this.setActivePath} isLoading={this.state.loading} userOrg={this.state.loggedInCustomerOrganization} />} />
                                            <ProtectedRoute exact path="/map" loggedIn={this.state.loggedIn} render={(props) => <Map {...props} currentPartnerId={this.state.currentPartnerId} isPartnerAdmin={this.state.isPartnerAdmin} currentCustomerId={this.state.currentCustomerId} setCurrentCustomerId={this.setCurrentCustomerId} partnerList={this.state.partnerList} setActivePath={this.setActivePath} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/scheduleRepairs" loggedIn={this.state.loggedIn} render={(props) => <ScheduleRepairs {...props} currentCustomerId={this.state.currentCustomerId} isLoading={this.state.loading} currentPartnerId={this.state.currentPartnerId} />} />
                                            <ProtectedRoute exact path='/currentJob' loggedIn={this.state.loggedIn} render={(props) => <CurrentJob {...props} currentCustomerId={this.state.currentCustomerId} setActivePath={this.setActivePath} isLoading={this.state.loading} />} />

                                            <ProtectedRoute exact path="/account" loggedIn={this.state.loggedIn} render={(props) => <Account {...props} onLogOff={this.onLogOff} roles={this.state.roles} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} isPartnerAdmin={this.state.isPartnerAdmin} isLoading={this.state.loading} reparentStatus={this.state.reparentStatus} setReparent={this.setReparent} passwordEmailSent={this.passwordEmailSent} endForgotPassword={this.endForgotPassword} isMobile={this.state.isMobile} hasServiceTitan={this.state.hasServiceTitan} hasBilling={this.state.hasBilling} />} />
                                            <ProtectedRoute exact path="/customerAlerts" loggedIn={this.state.loggedIn} render={(props) => <CustomerAlerts {...props} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} setActivePath={this.setActivePath} isLoading={this.state.loading} userOrg={this.state.loggedInCustomerOrganization} />} />
                                            <ProtectedRoute path="/home" loggedIn={this.state.loggedIn} render={(props) => <Home {...props} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} isLoading={this.state.loading} hasServiceTitan={this.state.hasServiceTitan} hasBilling={this.state.hasBilling} />} />

                                            <ProtectedRoute exact path="/assets" loggedIn={this.state.loggedIn} render={(props) => <Assets {...props} setActivePath={this.setActivePath} serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} currentCustomerId={this.state.currentCustomerId} currentAccount={this.state.currentAccount} faveAssetPath={this.state.faveAssetPath} isLoading={this.state.loading} favoriteAssets={this.state.favoriteAssets} setAssetFavorites={this.setAssetFavorites} isPartnerAdmin={this.state.isPartnerAdmin} isAdmin={this.state.roles.includes("ADMIN")} isActive={this.state.isActive} />} />
                                            <ProtectedRoute exact path="/devices" loggedIn={this.state.loggedIn} render={(props) => <Devices {...props} currentCustomerId={this.state.currentCustomerId} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/installCheck" loggedIn={this.state.loggedIn} render={(props) => <InstallCheck {...props} currentCustomerId={this.state.currentCustomerId} roles={this.state.roles} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/provisioning" loggedIn={this.state.loggedIn} render={(props) => <Provisioning {...props} currentCustomerId={this.state.currentCustomerId} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/billingInfo" loggedIn={this.state.loggedIn} render={(props) => <BillingInfo {...props} onLogOff={this.onLogOff} currentCustomerId={this.state.currentCustomerId} isLoading={this.state.loading} customers={this.state.customerList} />} />

                                            <ProtectedRoute exact path="/accountManagement" loggedIn={this.state.loggedIn} render={(props) => <AccountManagement {...props} currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} isLoading={this.state.loading} roles={this.state.roles} currentAccount={this.state.currentAccount} serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} isPartnerAdmin={this.state.isPartnerAdmin} attributeList={this.state.attributeList} />} />
                                            <ProtectedRoute exact path="/users" loggedIn={this.state.loggedIn} render={(props) => <Users {...props} currentPartnerId={this.state.currentPartnerId} isPartnerAdmin={this.state.isPartnerAdmin} isPortalAdmin={this.state.isPortalAdmin} isLoading={this.state.loading} roles={this.state.roles} />} />
                                            <ProtectedRoute exact path="/useraccount" loggedIn={this.state.loggedIn} render={(props) => <UserAccount {...props} currentAccount={this.state.currentAccount} updateAccountInfo={this.setAccountInfo} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/addPartner" loggedIn={this.state.loggedIn} render={(props) => <AddPartner {...props} roles={this.state.roles}  isLoading={this.state.loading} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} isPartnerAdmin={this.state.isPartnerAdmin} partners={this.state.partnerList} setPartnerList={list => { this.setState({ partnerList: list }); }} setActivePath={this.setActivePath} setCurrentPartnerId={this.setCurrentPartnerId} switchToNewPartner={this.switchToNewPartner/*(list, partner) => { this.switchToNewPartner(list, partner); }*/} />} />
                                            <ProtectedRoute exact path="/emailConfirmation" loggedIn={this.state.loggedIn} render={(props) => <ConfirmationEmail {...props} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/emailStatus" loggedIn={this.state.loggedIn} render={(props) => <EmailStatus {...props} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/inviteHomeowner" loggedIn={this.state.loggedIn} render={(props) => <InviteHomeowner {...props} isLoading={this.state.loading} currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} hasBilling={this.state.hasBilling} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} rentalAgreementCustomerValue={this.state.rentalAgreementCustomerValue} attributeList={this.state.attributeList} />} />
                                            <ProtectedRoute exact path="/inviteAdmins" loggedIn={this.state.loggedIn} render={(props) => <InviteAdmins {...props} isLoading={this.state.loading} currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} hasBilling={this.state.hasBilling} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} rentalAgreementCustomerValue={this.state.rentalAgreementCustomerValue} attributeList={this.state.attributeList} />} />
                                            <ProtectedRoute exact path="/energyMetrics" loggedIn={this.state.loggedIn} render={(props) => <PartnerEnergyMetrics {...props} isLoading={this.state.loading} currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} />} />
                                            <ProtectedRoute exact path="/deletedAccounts" loggedIn={this.state.loggedIn} render={(props) => <DeletedAccounts {...props} isLoading={this.state.loading} currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} _isMounted={ this._isMounted } />} />

                                            <ProtectedRoute exact path="/iottest" loggedIn={this.state.loggedIn} render={(props) => <IotTest {...props} currentAccount={this.state.currentAccount} updateAccountInfo={this.setAccountInfo} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/accessKeyTool" loggedIn={this.state.loggedIn} render={(props) => <AccessKeyTool {...props} currentAccount={this.state.currentAccount} updateAccountInfo={this.setAccountInfo} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/manageAssets" loggedIn={this.state.loggedIn} render={(props) => <ManageAssets {...props} currentAccount={this.state.currentAccount} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo} isLoading={this.state.loading} />} />
                                            <ProtectedRoute exact path="/otaLogs" loggedIn={this.state.loggedIn} render={(props) => <OTALogs {...props} isLoading={this.state.loading} /> } />
                                            
                                            <ProtectedRoute exact path="/forgotPassword" loggedIn={this.state.loggedIn} render={(props) => <ForgotPassword {...props} setActivePath={this.setActivePath} />} />
                                            <ProtectedRoute exact path="/manageFavorites" loggedIn={this.state.loggedIn} render={(props) => <ManageFavorites {...props} onSave={this.onSaveFaves} partners={this.state.partnerList} customers={this.state.customerList} favorites={this.state.favoriteCustomers/*favoritesList*/} favoriteCustomers={this.state.favoriteCustomers} favoriteAssets={this.state.favoriteAssets} userName={this.state.currentAccount.email} selectedPartner={this.state.currentPartnerId} setFavoritesList={this.setFavoritesList} isLoading={this.state.isLoading} />} />
                                            <ProtectedRoute exact path="/userManagement" loggedIn={this.state.loggedIn} render={(props) => <UserManagement {...props} isLoading={this.state.loading} />} />

                                            <ProtectedRoute exact path="/airConditionerOnboarding" loggedIn={this.state.loggedIn} render={(props) => <ACOnboarding {...props} isLoading={this.state.loading} setActivePath={this.setActivePath} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />} />
                                            <ProtectedRoute exact path="/acAssetTrainingReview" loggedIn={this.state.loggedIn} render={(props) => <ACAssetTrainingReview {...props} isLoading={this.state.loading} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />} />
                                            <ProtectedRoute exact path="/acTrainingAlertCountReview" loggedIn={this.state.loggedIn} render={(props) => <ACAlertTrainingReview {...props} isLoading={this.state.loading} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />} />
                                            <ProtectedRoute exact path="/furnaceOnboarding" loggedIn={this.state.loggedIn} render={(props) => <FurnaceOnboarding {...props} isLoading={this.state.loading} setActivePath={this.setActivePath} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />} />
                                            <ProtectedRoute exact path="/furnaceAssetTrainingReview" loggedIn={this.state.loggedIn} render={(props) => <FurnaceAssetTrainingReview {...props} isLoading={this.state.loading} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} currentAccount={this.state.currentAccount} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />} />
                                            <ProtectedRoute exact path="/furnaceTrainingAlertCountReview" loggedIn={this.state.loggedIn} render={(props) => <FurnaceAlertTrainingReview {...props} isLoading={this.state.loading} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />} />

                                            <ProtectedRoute exact path="/subscriptions" loggedIn={this.state.loggedIn} render={(props) => <Subscriptions {...props} currentPartnerId={this.state.currentPartnerId} isLoading={this.state.loading}  loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} attributeList={this.state.attributeList} />} />
                                        </Switch>


                                    </Router>

                                </React.Fragment>
                            </div>
                        ) : <Login onLogonChange={this.onLoggedInUserChange} onForgotPassword={this.onForgotPassword} />;
    }
}
export default withRouter(withStyles(styles)(App))
