import React, { useState, useEffect, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import { Spinner, Image } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardActionArea } from '@material-ui/core';
import { Cell, ResponsiveContainer, Legend, PieChart, Pie} from 'recharts';
import BasicHighchartGraph from '../Graph/BasicHighchartGraph';
import { assetType, assetTypeStrings } from "../../componentObjects";
import FalsePositiveTypeModal from './FalsePositiveTypeModal';
import MonthSummaryModal from './MonthSummaryModal';
import moment, {months} from 'moment';
import { COLORS } from "../../componentObjects";
import DatePicker from 'react-datepicker';
import triageService from "../../services/triage.service";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const JOB_VALUE = 136.1;

function AlertTriageDashboard(props) {
    const { classes, isLoading, history, setActivePath, _isMounted } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [metricsList, setMetricsList] = useState([]);
    const [dailyMetricsList, setDailyMetricsList] = useState([]);
    const [assetCountList, setAssetCountList] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    const [tileContent, setTileContent] = useState({});
    const [triageUserContent, setTriageUserContent] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [contentLoading, setContentLoading] = useState(false);
    const [selectedAssetType, setSelectedAssetType] = useState(assetTypeStrings.ALL);
    const [fpAssetType, setFpAssetType] = useState(assetTypeStrings.ALL);
    const [falsePositiveCounts, setFalsePositiveCounts] = useState({});
    const [pieChartData, setPieChartData] = useState({});
    const [activeIndex, setActiveIndex] = useState(null);
    const [falsePositiveStartTime, setFalsePositiveStartTime] = useState(moment().subtract(1, 'days').startOf('day'));
    const [falsePositiveEndTime, setFalsePositiveEndTime] = useState(moment());
    const [searchChanged, setSearchChanged] = useState(false);
    const [pieChartLoading, setPieChartLoading] = useState(false);
    const [isShowFalsePositiveTypeModal, setIsShowFalsePositiveTypeModal] = useState(false);
    const [isShowDownloadSummaryModal, setIsShowDownloadSummaryModal] = useState(false);
    const [monthSummary, setMonthSummary] = useState([]);
    const [monthSummaryLoading, setMonthSummaryLoading] = useState(false);

    const onPieEnter = (data, index) => {
        setActiveIndex(index);
    };

    const onChangeStart = (e) => {
        setFalsePositiveStartTime(moment(e).startOf('day'));
        setSearchChanged(true);
    }

    const onChangeEnd = (e) => {
        setFalsePositiveEndTime(moment(e).endOf('day'));
        setSearchChanged(true);
    }

    const updateSearch = () => {
        setPieChartLoading(true);
        setSearchChanged(false);
        populatePieChartData(fpAssetType);
    }

    const populateInfo = () => {
        setContentLoading(true);
        if (_isMounted && isMounted) {
            triageService.gettriagedashboarddata()
                .then(response => {
                    if (_isMounted && isMounted) {
                        PageView();
                        setDailyMetricsList(groupDataByDay(response.triageAlertMetrics));
                        setMetricsList(response.triageAlertMetrics);
                        setAssetCountList(getAssetCounts(response.assetCounts));
                        setDashboardData(response);
                        setTileContent(generatePastDayMetrics(response));
                        setContentLoading(false);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    const populatePieChartData = (fpAssetType = assetTypeStrings.ALL) => {
        if (_isMounted && isMounted) {
            var startStr = falsePositiveStartTime.toISOString();
            var endStr = falsePositiveEndTime.toISOString();
            PageView();
            triageService.gettriagefalsepositivecounts(startStr, endStr)
                .then(response => {
                    var topTen = [];
                    if (response.length > 10) {
                        for (let i = 0; i < 10; i++) {
                            topTen[i] = response[i];
                        }
                    }
                    if (_isMounted && isMounted) {
                        setPieChartData(generatePieChartData(topTen, fpAssetType));
                        setFalsePositiveCounts(topTen);
                        setPieChartLoading(false);
                    }
                })
                .catch(e => {
                    console.log(e);
                    setPieChartLoading(false);
                });
        }
    }

    const populateMonthSummaryData = (month, year) => {
        setMonthSummaryLoading(true);
        if (_isMounted && isMounted) {
            PageView();
            triageService.getmonthlytriagereport(month, year)
                .then(response => {
                    if (_isMounted && isMounted) {
                        setMonthSummary(response);
                        setMonthSummaryLoading(false);
                    }
                })
                .catch(e => {
                    console.log(e);
                    setMonthSummaryLoading(false);
                });
        }
    }

    const generatePieChartData = (data, selectedAssetType = assetTypeStrings.ALL) => {
        if (selectedAssetType === assetTypeStrings.ALL){
            return data
        }
        else if (selectedAssetType === assetTypeStrings.FURNACE){
            return data.filter(item => item.assetTypeId === assetType.Furnace);
        }
        else if (selectedAssetType === assetTypeStrings.AC){
            return data.filter(item => item.assetTypeId === assetType.AirConditioner);
        }
        return data;
    }

    const groupDataByDay = (sessionData) => {
        let reducedData =  sessionData.reduce((result, obj) => {
            const { sessionDate, dailyId, furnaceAlertCount, airConditionerAlertCount } = obj;
            const key = `${dailyId}-${sessionDate}`;

            if (!result[key]) {
                result[key] = { sessionDate, dailyId, furnaceAlertCount, airConditionerAlertCount };
            } else {
                result[key].furnaceAlertCount += furnaceAlertCount;
                result[key].airConditionerAlertCount += airConditionerAlertCount;
            }

            return result;
        }, {});

        return Object.values(reducedData).map(group => ({
            sessionDate: group.sessionDate,
            dailyId: group.dailyId,
            furnaceAlertCount: group.furnaceAlertCount,
            airConditionerAlertCount: group.airConditionerAlertCount
        }));
    }

    const generatePastDayMetrics = (data, assetType = assetTypeStrings.ALL) => {
        let assetCountList = getAssetCounts(data.assetCounts);
        let metricsList = data.triageAlertMetrics;
        let furnaceRunData = data.runCountStats.filter(x => x.assetType === "Furnace");
        let acRunData = data.runCountStats.filter(x => x.assetType === "AirConditioner");

        let twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
        let assetCountLatest = assetCountList[assetCountList.length - 1];
        let alertCount = 0;
        let tileMetrics = {
            alerts: 0,
            escalations: 0,
            triageTime: 0,
            assets: 0,
            runs: (
                assetType == assetTypeStrings.ALL ? furnaceRunData[furnaceRunData.length - 1].runs + acRunData[acRunData.length - 1].runs
                : assetType == assetTypeStrings.FURNACE ? furnaceRunData[furnaceRunData.length - 1].runs
                        : assetType == assetTypeStrings.AC ? acRunData[acRunData.length - 1].runs : 0
            )
        };

        let sessionsWithinLast24Hours = metricsList.filter(obj => {
            let sessionDateString = new Date(obj.sessionDateTime);
            if (sessionDateString >= twentyFourHoursAgo)
                return obj;
        });

        setTriageUserContent(groupDataByUser(sessionsWithinLast24Hours));

        if (assetType === assetTypeStrings.ALL) {
            sessionsWithinLast24Hours.forEach(session => {
                tileMetrics.alerts += session.furnaceAlertCount + session.airConditionerAlertCount;
                tileMetrics.escalations += session.escalatedFurnaceAlertCount + session.escalatedAirConditionerAlertCount;
                tileMetrics.triageTime += session.sessionDurationMinutes;
            });
            tileMetrics.assets = assetCountLatest.value;
        }
        else if (assetType == assetTypeStrings.FURNACE) {
            sessionsWithinLast24Hours.forEach(session => {
                if (session.furnaceAlertCount > 0) {
                    tileMetrics.alerts += session.furnaceAlertCount
                    tileMetrics.escalations += session.escalatedFurnaceAlertCount;
                    tileMetrics.triageTime += session.sessionDurationMinutes;
                }
            });
            tileMetrics.assets = data.assetCounts.furnacesrunninganalytics[data.assetCounts.furnacesrunninganalytics.length - 1].Value;
        }
        else if (assetType == assetTypeStrings.AC) {
            sessionsWithinLast24Hours.forEach(session => {
                if (session.airConditionerAlertCount > 0) {
                    tileMetrics.alerts += session.airConditionerAlertCount;
                    tileMetrics.escalations += session.escalatedAirConditionerAlertCount;
                    tileMetrics.triageTime += session.sessionDurationMinutes;
                }
            });
            tileMetrics.assets = data.assetCounts.airconditionersrunninganalytics[data.assetCounts.airconditionersrunninganalytics.length - 1].Value;
        }

        return tileMetrics;
    }

    const groupDataByUser = (sessionData) => {
        if (sessionData !== undefined) {

            let reducedData = sessionData.reduce((result, obj) => {
                const { userId, userName, sessionDurationMinutes, furnaceAlertCount, airConditionerAlertCount,
                    heatPumpAlertCount, airHandlerAlertCount, escalatedFurnaceAlertCount, escalatedAirConditionerAlertCount,
                    escalatedHeatPumpAlertCount, escalatedAirHandlerAlertCount, falsePositiveFurnaceAlertCount,
                    falsePositiveAirConditionerAlertCount, falsePositiveHeatPumpAlertCount, falsePositiveAirHandlerAlertCount } = obj;
                const key = `${userId}`;

                let alertCount = furnaceAlertCount + airConditionerAlertCount + heatPumpAlertCount + airHandlerAlertCount;
                let escalationCount = escalatedFurnaceAlertCount + escalatedAirConditionerAlertCount + escalatedHeatPumpAlertCount + escalatedAirHandlerAlertCount;
                let falsePositiveCount = falsePositiveFurnaceAlertCount + falsePositiveAirConditionerAlertCount + falsePositiveHeatPumpAlertCount + falsePositiveAirHandlerAlertCount;
                let triageTime = sessionDurationMinutes;
                if (!result[key]) {
                    result[key] =
                    {
                        userId, userName, triageTime,
                        alertCount, escalationCount, falsePositiveCount
                    };
                } else {
                    result[key].userName = userName;
                    result[key].triageTime += triageTime;
                    result[key].alertCount += alertCount;
                    result[key].escalationCount += escalationCount;
                    result[key].falsePositiveCount += falsePositiveCount;
                }

                return result;
            }, {});

            return Object.values(reducedData).map(group => ({
                userName: group.userName,
                triageTime: group.triageTime,
                alertCount: group.alertCount,
                escalationCount: group.escalationCount,
                falsePositiveCount: group.falsePositiveCount
            }));
        }
    }

    const getAlertChartData = (data) => {
        let chartDataList = [];

        if (data !== undefined && data.length && assetCountList !== undefined && assetCountList.length) {
            let filledData = fillMissingDates(data);
            let pointMoment = moment(data[0].sessionDate).add(moment().utcOffset() * -1, 'minutes').format("l")
            var dt = new Date(pointMoment);
            var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());

            let furnaceArr = filledData.map((item) => (item.furnaceAlertCount));
            const furnaceData = {
                name: `Furnace Alerts`,
                yAxis: 0,
                data: furnaceArr,
                color: '#176a95',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(furnaceData);

            let acArr = filledData.map((item) => (item.airConditionerAlertCount));
            const acData = {
                name: `AC Alerts`,
                yAxis: 0,
                data: acArr,
                color: 'red',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(acData);

            let alertsPerAssetArr = filledData.map((item) => {
                let matchingAssetCount = assetCountList.filter(a => new Date(item.sessionDate).toDateString() === new Date(a.date).toDateString());
                let assetCount = matchingAssetCount.length > 0 ? matchingAssetCount[0].value : assetCountList[0].value;
                let alertsPerAsset = Math.round(((item.furnaceAlertCount + item.airConditionerAlertCount) / assetCount) * 100) / 100;
                return alertsPerAsset;
            });

            const alertsPerAssetData = {
                name: `Alerts/Asset`,
                yAxis: 1,
                data: alertsPerAssetArr,
                color: 'orange',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(alertsPerAssetData);
        }

        return chartDataList;
    }

    const getRunChartData = (data) => {
        let chartDataList = [];

        if (data !== undefined && data.triageAlertMetrics !== undefined && data.triageAlertMetrics.length) {
            let alertData = dailyMetricsList;
            let runCountData = data.runCountStats;

            let filledData = fillMissingDates(alertData);
            let pointMoment = moment(alertData[0].sessionDate).add(moment().utcOffset() * -1, 'minutes').format("l")
            var dt = new Date(pointMoment);
            var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());

            let furnaceArr = filledData.map((item) => (item.furnaceAlertCount));
            const furnaceData = {
                name: `Furnace Alerts`,
                yAxis: 0,
                data: furnaceArr,
                color: '#176a95',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(furnaceData);

            let furnaceRunsArr = runCountData.filter((item) => item.assetType === "Furnace").map((item) => (item.runs));
            const furnaceRunData = {
                name: `Furnace Runs`,
                yAxis: 1,
                data: furnaceRunsArr,
                color: '#176a95',
                dashStyle: 'dot',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(furnaceRunData);

            let acArr = filledData.map((item) => (item.airConditionerAlertCount));
            const acData = {
                name: `AC Alerts`,
                yAxis: 0,
                data: acArr,
                color: 'red',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(acData);

            let acRunsArr = runCountData.filter((item) => item.assetType === "AirConditioner").map((item) => (item.runs));
            const acRunData = {
                name: `AC Runs`,
                yAxis: 1,
                data: acRunsArr,
                color: 'red',
                dashStyle: 'dot',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(acRunData);
        }

        return chartDataList;
    }

    const getAssetCounts = (data) => {
        let combinedCounts = {};

        Object.keys(data).forEach(assetType => {
            data[assetType].forEach(entry => {
                let date = new Date(entry.Timestamp).toISOString().split('T')[0]; // Extract date from timestamp
                if (!combinedCounts[date]) {
                    combinedCounts[date] = { date: date, value: parseInt(entry.Value, 10) };
                } else {
                    combinedCounts[date].value += parseInt(entry.Value, 10);
                }
            });
        });

        // Convert combinedCounts object to an array of objects
        let result = Object.values(combinedCounts);
        return result;
    }

    function fillMissingDates(data) {
        const filledData = [];
        const startDate = new Date(data[0].sessionDate);
        const endDate = new Date(data[data.length - 1].sessionDate);
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const matchingData = data.find(item => new Date(item.sessionDate).toDateString() === currentDate.toDateString());
            if (matchingData) {
                filledData.push(matchingData);
            } else {
                filledData.push({ sessionDate: currentDate.toISOString(), furnaceAlertCount: 0, airConditionerAlertCount: 0 });
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return filledData;
    }

    const formatMinutes = (totalMinutes) => {
        if (totalMinutes == 0) return '0 m';
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        let formattedTime = '';
        if (hours > 0) {
            formattedTime += `${hours} h`; // Pluralize "hour" if necessary
        }
        if (minutes > 0) {
            formattedTime += ` ${minutes} m`; // Pluralize "minute" if necessary
        }

        return formattedTime.trim(); // Trim any leading/trailing whitespace
    }

    const onAssetTypeChange = (e) => {
        let assetTypeValue = e.target.value
        setSelectedAssetType(assetTypeValue);
        setTileContent(generatePastDayMetrics(dashboardData, assetTypeValue));
    }

    const onFpAssetTypeChange = (e) => {
        let assetTypeValue = e.target.value
        setFpAssetType(assetTypeValue);
        setPieChartData(generatePieChartData(falsePositiveCounts, assetTypeValue));
    }

    const onTriageMetricsClick = () => {
        Event("Triage Metrics clicked", "User clicked button to go to Alert Triage Details page", "Triage Metrics clicked");
        setActivePath("/alertTriageDetails");
        history.push("/alertTriageDetails");
    }

    const onPieChartClick = () => {
        showFalsePositiveTypeModal();
    }

    const showFalsePositiveTypeModal = () => {
        setIsShowFalsePositiveTypeModal(!isShowFalsePositiveTypeModal);
    }

    const onDownloadSummaryClick = () => {
        showDownloadSummaryModal();
    }

    const showDownloadSummaryModal = () => {
        if(!isShowDownloadSummaryModal){
            const now = new Date();
            const currentMonth = now.getMonth() + 1;
            const currentYear = now.getFullYear();
            populateMonthSummaryData(currentMonth, currentYear);
        }
        setIsShowDownloadSummaryModal(!isShowDownloadSummaryModal);
    }

    useEffect(() => {
        setIsMounted(true);

        if (!isLoading) {
            populatePieChartData();
            populateInfo();
            setContentLoading(false);
        }

        return () => {
            setIsMounted(false);        
        };
    }, [isMounted]);

    const renderMetricsContent = () => {
        let triageUserMetrics = <div className="row no-gutters card-row"
                                     style={{textAlign: 'center', margin: '8px 0px'}}>
            <div className="col" style={{minHeight: '40px'}}>
                <div style={{margin: '0px 20px'}}>
                    <table style={{width: '100%', marginBottom: '0'}}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 30px 10px' }}>
                                    NAME
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '0px 0px 10px' }}>
                                    ALERTS TRIAGED
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 10px 10px 40px' }}>
                                    TRIAGE TIME
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 10px 10px 40px' }}>
                                    ESCALATIONS
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 10px 10px 40px' }}>
                                    FALSE POSITIVES
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {triageUserContent !== undefined && triageUserContent.map((user, index) =>
                                <tr key={user.userName} data={index} className="alerts-table-row">
                                    <td style={{ textAlign: 'left', padding: '8px 3px 8px 30px' }}> {user.userName} </td>
                                    <td style={{ textAlign: 'center', padding: '8px 3px' }}> {user.alertCount} </td>
                                    <td style={{ textAlign: 'center', padding: '8px 3px' }}> {formatMinutes(user.triageTime)} </td>
                                    <td style={{ textAlign: 'center', padding: '8px 3px' }}> {user.escalationCount} </td>
                                    <td style={{ textAlign: 'center', padding: '8px 3px 8px 40px' }}> {user.falsePositiveCount} </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;

        let DailyStatsHeader =
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <h6 style={{ paddingLeft: '15px', marginRight: '15px' }}>Last 24 Hours</h6>
                        <span className="select-message">Asset Type: <br />
                            <select className="asset-select-picker" style={{ width: 150 + 'px'}} value={selectedAssetType} onChange={onAssetTypeChange}>
                                {Object.keys(assetTypeStrings).map(key => (
                                    <option key={key} value={assetTypeStrings[key]}>{assetTypeStrings[key]}</option>
                                ))}
                            </select>
                        </span>
                    </div>;

        let startTime = falsePositiveStartTime.toDate();
        let endTime = falsePositiveEndTime.toDate();

        let falsePositiveDatePicker =
            <div className="alerts-tab-wrapper"
                style={{margin: '0 30px', backgroundColor: "#f7f9fa", padding: '10px 0'}}>
                <div style={{paddingLeft: '40px', margin: '5px'}}>
                    <div className="reactive-dashboard" style={{
                        height: '24px',
                        alignItems: 'center',
                        justifyContent: "space-between"
                    }}>
                        <div className="reactive-dashboard" style={{margin: "0 auto"}}>
                                <span className="select-message">Asset Type: <br/>
                                        <select className="asset-select-picker" style={{width: 150 + 'px'}}
                                                value={fpAssetType}
                                                onChange={onFpAssetTypeChange}>
                                            {Object.keys(assetTypeStrings).map(key => (
                                                <option key={key}
                                                        value={assetTypeStrings[key]}>{assetTypeStrings[key]}</option>
                                            ))}
                                        </select>
                                </span>
                            <span style={{}}>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div style={{marginLeft: '10px'}}>Start Day:</div>
                                                        <DatePicker
                                                            customInput={<input className="txt-detail"
                                                                                disabled={false}
                                                                                style={{
                                                                                    minWidth: '200px',
                                                                                    textAlign: "center"
                                                                                }}/>}
                                                            id="startDate"
                                                            name="startDate"
                                                            selected={startTime}
                                                            onChange={date => {
                                                                onChangeStart(date);
                                                            }}
                                                            dateFormat="MM/dd/yyyy"
                                                            popperPlacement="top-end"
                                                            popperProps={{
                                                                positionFixed: true
                                                            }}
                                                        />
                                                    </div>
                                                </span>
                            <span style={{}}>
                                                    <div style={{textAlign: 'center'}}>
                                                        <div style={{marginLeft: '10px'}}>End Day:</div>
                                                        <DatePicker
                                                            customInput={<input className="txt-detail"
                                                                                disabled={false}
                                                                                style={{
                                                                                    minWidth: '200px',
                                                                                    textAlign: "center"
                                                                                }}/>}
                                                            id="endDate"
                                                            name="endDate"
                                                            selected={endTime}
                                                            onChange={date => {
                                                                onChangeEnd(date);
                                                            }}
                                                            dateFormat="MM/dd/yyyy"
                                                            popperPlacement="top-end"
                                                            popperProps={{
                                                                positionFixed: true
                                                            }}
                                                        />
                                                    </div>
                                                </span>

                            <button className="secondary-btn btn-small" onClick={updateSearch} disabled={!searchChanged}
                                    style={{
                                        minWidth: '50px',
                                        height: '38px',
                                        borderRadius: '.25rem',
                                        padding: '0px 10px',
                                        marginLeft: '12px',
                                        marginTop: '10px'
                                    }}>Update Dates
                            </button>
                        </div>
                    </div>
                </div>
            </div>;


            let pieChart = pieChartLoading ?
                <div className="loading-wrapper" style={{top:'25%'}}>
                    <div className="modal-body"><Spinner animation="border" variant="dark"/></div>
                </div> 
                :
                <PieChart width={400} height={600} onMouseEnter={null} onClick={onPieChartClick}>
                    <Pie
                        data={pieChartData}
                        innerRadius={75}
                        outerRadius={125}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="alertTypeCount"
                        nameKey="alertTypeName"
                        opacity="65%"
                        label={true}
                        onMouseOver={onPieEnter}
                        isAnimationActive={false}
                    >
                        {pieChartData.length > 0 &&
                            (pieChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke={activeIndex === index ? '#333' : 'none'}
                                    strokeWidth={activeIndex === index ? 2 : 0}/>
                            )))
                        }
                    </Pie>

                    <Legend verticalAlign="bottom" iconType="circle" iconSize={6}
                            wrapperStyle={{opacity: '75%', padding: '0px 10px'}}
                            onMouseEnter={onPieEnter}
                            formatter={(value, entry, index) => {
                                const activeStyle = {
                                    fontWeight: activeIndex === index ? 'bold' : 'normal',
                                    color: activeIndex === index ? COLORS[index % COLORS.length] : 'inherit'
                                };
                                return <span style={activeStyle}>{value}</span>;
                            }}
                    />
                </PieChart>

        return (
            <div>
                <div className="row" style={{ padding: '30px' }}>

                    <div className="col-lg-8 col-md-6" style={{ display: 'inline', marginBottom: '20px' }}>
                        <div>

                            <div className="row no-gutters" style={{ marginBottom: '20px' }} >
                                <div className="col">

                                    <Card elevation={4} style={{ height: '100%' }}>
                                        <CardActionArea style={{ height: '100%' }}>
                                            <CardContent style={{ padding: '10px 0px' }}>

                                                <h6 style={{ paddingLeft: '15px' }}>Alerts Report</h6>
                                                <div className="row no-gutters card-row" style={{ textAlign: 'center', minHeight: '110px !important' }}>
                                                    <div className="col" style={{ minHeight: '110px' }}>
                                                        <BasicHighchartGraph chartData={getAlertChartData(dailyMetricsList)} yAxisTitle='Alerts' yAxisTitle2='Alerts/Assets' />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </div>

                            <div className="row no-gutters" style={{ marginBottom: '20px' }} >
                                <div className="col">

                                    <Card elevation={4} style={{ height: '100%' }}>
                                        <CardActionArea style={{ height: '100%' }}>
                                            <CardContent style={{ padding: '10px 0px' }}>

                                                <h6 style={{ paddingLeft: '15px' }}>Runs Report</h6>
                                                <div className="row no-gutters card-row" style={{ textAlign: 'center', minHeight: '110px !important' }}>
                                                    <div className="col" style={{ minHeight: '110px' }}>
                                                        <BasicHighchartGraph chartData={getRunChartData(dashboardData)} yAxisTitle='Alerts' yAxisTitle2='Runs' />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </div>

                            <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={onTriageMetricsClick}>
                                <div className="col">

                                    <Card elevation={4} style={{ height: '100%' }}>
                                        <CardActionArea style={{ height: '100%' }}>
                                            <CardContent style={{ padding: '10px 0px' }}>

                                                <h6 style={{ paddingLeft: '15px' }}>Triage User Metrics</h6>
                                                <div className="row no-gutters card-row" style={{ textAlign: 'center', minHeight: '110px !important' }}>
                                                    <div className="col" style={{ minHeight: '110px' }}>
                                                        {triageUserMetrics}
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </div>

                            <div className="row no-gutters" style={{marginBottom: '20px'}} >
                                <div className="col">
                                    <Card elevation={4} style={{height: '100%'}}>
                                        <CardContent style={{padding: '10px 0px'}}>
                                            <h6 style={{paddingLeft: '15px'}}>False Positive Alert Type Counts</h6>

                                            <ResponsiveContainer height={500}>
                                                {pieChart}
                                            </ResponsiveContainer>
                                            <br />
                                            {falsePositiveDatePicker}
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" style={{ display: 'inline' }}>
                        <div>
                            <div className="row" style={{ height: '100%' }}>
                                <div className="col-12" style={{ display: 'grid' }}>
                                    <Card elevation={4} style={{ backgroundColor: '#e6e6e6' }}>
                                        <CardContent style={{ padding: '10px 0px' }}>
                                            {DailyStatsHeader}

                                            <div className="col-12" style={{ display: 'grid' }}>
                                                <div>
                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Escalations</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', color: 'red', justifyContent: 'center', alignItems: 'center' }}>{tileContent.escalations}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Alerts</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{tileContent.alerts}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}> Asset Count </h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{ tileContent.assets }</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Runs</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{parseInt(tileContent.runs, 10).toLocaleString()}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Triage Time</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{formatMinutes(tileContent.triageTime)}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Assets/FTE (90%)</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{(((432 / tileContent.triageTime) * tileContent.assets).toFixed(0)).toLocaleString()}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Assets/FTE (100%)</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{(((480 / tileContent.triageTime) * tileContent.assets).toFixed(0)).toLocaleString()}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Alerts / Assets</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{(tileContent.alerts / tileContent.assets).toFixed(2)}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Escalations / Alert</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>{((tileContent.escalations / tileContent.alerts) * 100).toFixed(2)}%</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="row no-gutters" style={{ marginBottom: '20px' }}>
                                                        <div className="col">
                                                            <Card elevation={4} style={{ height: '100px' }}>
                                                                <CardContent style={{ padding: '10px 0px' }} >
                                                                    <h6 style={{ paddingLeft: '15px' }}>Customer Revenue</h6>
                                                                    <div style={{ display: 'flex', fontSize: '2.25rem', justifyContent: 'center', alignItems: 'center' }}>${((JOB_VALUE * tileContent.escalations * 0.85) + (5 * tileContent.escalations / 2)).toFixed(2)}</div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    let content = contentLoading ?
        <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
            <div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            </div></div>
        : renderMetricsContent();

    return (

        <div className={classes.root}>
            <div className='infoPage'>
                <div className="row no-gutters">
                    <div className="col">
                        <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                            Alert Triage Dashboard
                        </h2>
                    </div>
                    <div className="col" style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <button className="primary-btn btn-large" style={{ width: '250px' }} onClick={onDownloadSummaryClick}>Download Monthly Summary</button>
                    </div>
                </div>
                {content}
                <FalsePositiveTypeModal show={isShowFalsePositiveTypeModal} onClick={showFalsePositiveTypeModal} loading={pieChartLoading} selectedAssetType={fpAssetType} fpData={pieChartData} startTime={falsePositiveStartTime} endTime={falsePositiveEndTime}> </FalsePositiveTypeModal>
                <MonthSummaryModal show={isShowDownloadSummaryModal} onClick={showDownloadSummaryModal} updateData={populateMonthSummaryData} loading={monthSummaryLoading} monthData={monthSummary}> </MonthSummaryModal>
            </div>
        </div>
    );
}

export default withStyles(styles)(AlertTriageDashboard);
