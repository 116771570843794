/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/installs";
const installService = {
    getinstallbymacid: async (macId) => {
        const response = await http.post(`${controller}/getinstallbymacid?macId=${macId}&api-version=2.0`);
        return response.data;
    }
};
export default installService;
