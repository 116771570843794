/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";

class AddAssetModal extends Component { 
    
    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.props.newAsset.assetId= 0;
        this.props.newAsset.description= "";
        this.props.newAsset.mac= "";
        this.props.newAsset.make= "";
        this.props.newAsset.mfgDate= "";
        this.props.newAsset.model= "";
        this.props.newAsset.name= "";
        this.props.newAsset.nickname= "";
        this.props.newAsset.parentAssetId= 0;
        this.props.newAsset.path= [];
        this.props.newAsset.properties= [];
        this.props.newAsset.sensorFw= "";
        this.props.newAsset.sensorMac= "";
        this.props.newAsset.serialNumber= "";
        this.props.newAsset.status= 0;
        this.props.newAsset.type = "AirConditioner";
        this.props.newAsset.compressorLra = "";
        this.props.newAsset.compressorRla = "";
        this.props.newAsset.fanFla = "";
        this.props.newAsset.fanLra = "";
    };

    render() {
                
        if(!this.props.show){
            return null;
        }
        else{
            return (

                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Add Asset</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "left" }}>
                            <form>
                                <div>
                                    <label>Asset Type</label><br />
                                    <select className="selectpicker-modal" style={{ width: '220px' }} value={this.props.newAsset.type} onChange={this.props.onAssetTypeChange}>
                                        <option key="1" id="1" value="AirConditioner">AirConditioner</option>
                                        <option key="2" id="2" value="AirHandler">AirHandler</option>
                                        <option key="3" id="3" value="Boiler">Boiler</option>
                                        <option key="4" id="4" value="Furnace">Furnace</option>
                                        <option key="5" id="5" value="HeatPump">HeatPump</option>
                                        <option key="6" id="6" value="WaterHeater">WaterHeater</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Nickname</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nickname"
                                        value={this.props.newAsset.nickname}
                                        onChange={this.props.onNickNameChange}
                                    />
                                </div>
                                <div>
                                    <label>Make</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="make"
                                        value={this.props.newAsset.make}
                                        onChange={this.props.onMakeChange}
                                    />
                                </div>
                                <div>
                                    <label>Model</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="model"
                                        value={this.props.newAsset.model}
                                        onChange={this.props.onModelChange}
                                    />
                                </div>
                                <div>
                                    <label>Serial Number</label>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        id="serial"
                                        value={this.props.newAsset.serialNumber}
                                        onChange={this.props.onSerialNumberChange}
                                    />
                                </div>
                                <div style={{ marginBottom: '10px' }}>
                                    <label>Manufacture Date</label>
                                    <input 
                                        type="date"
                                        className="form-control"
                                        id="mfgDate"
                                        name="mfgDate"                                        
                                        onChange={this.props.onMFGDateChange}
                                    />
                                </div>
                                {this.props.newAsset.type === "AirConditioner" && <>
                                        <label>Compressor RLA</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cRla"
                                            name="cRla"
                                            onChange={this.props.onCompressorRlaChange}
                                            value={this.props.newAsset.compressorRla}
                                        />
                                        <label>Compressor LRA</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cLra"
                                            name="cLra"
                                            value={this.props.newAsset.compressorLra}
                                            onChange={this.props.onCompressorLraChange}
                                        />
                                        <label>Fan FLA</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fFla"
                                            name="fFla"
                                            value={this.props.newAsset.fanFla}
                                            onChange={this.props.onFanFlaChange}
                                        />
                                        <label>Fan LRA</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fLra"
                                            name="fLra"
                                            value={this.props.newAsset.fanLra}
                                            onChange={this.props.onFanLraChange}
                                        />
                                </>}
                            </form>
                        </div>


                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.props.onSave}>Save</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}   
  
export default AddAssetModal;